import React, { useEffect, useState } from "react";
import cellEditFactory from "react-bootstrap-table2-editor";
import BootstrapTable from "react-bootstrap-table-next";
import { Rewind } from "react-feather";

import { Cut } from "components/Cut";

import { priceFormat } from "utils/format";
import { BarChart } from "components/BarChart";
import { getWarehouses } from "services/apiWarehouse";
import { ChangeWarehouse } from "../ChangeWarehouse";
import { SkladPopup } from "features/Orders/SkladPopup";
import { removeReturnToProviderProduct, updateReturnToProviderProduct } from "services/apiSupply";


import "./returnToProviderProductTable.scss";
import { TableButton } from "components/TableButton";

export const ReturnToProviderProductTable = ({
  products,
  loading,
  setLoading,
  setProducts,
}) => {
  const [warehouses, setWarehouses] = useState([]);

  const getExistRender = (row) => {
    if (row.inStockAll) {
      if (row.inStockAll === row.inStock) {
        return row.inStock;
      }
      return `${row.inStock || 0} (${row.inStockAll})`;
    }
    return row.inStock;
  };

  const getAndSetWarehouses = () => {
    getWarehouses()
      .then(setWarehouses)
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    getAndSetWarehouses();
  }, []);

  const productHandler = (oldData, newData, row) => {
    if (String(oldData) === String(newData)) return;
    setLoading(true);
    updateReturnToProviderProduct({
      ...row,
      count: row.count,
      warehouse: row.warehouse?.id,
    })
      .then((res) => {
        setProducts(res.products);
      })
      .finally(() => setLoading(false));
  };

  const removeHandler = (id) => {
    setLoading(true);
    removeReturnToProviderProduct({
      id
    })
      .then((res) => {
        setProducts(res.products);
      })
      .finally(() => setLoading(false));
  };

  const tableProductsColumns = [
    {
      dataField: "index",
      text: "№",
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "article",
      text: "Артикул",
      formatter: (cell, row) => row.nomenclature?.article,
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      classes: () => "bold",
      editable: false,
      footer: () => null,
    },
    {
      dataField: "brand",
      text: "Бренд",
      formatter: (cell, row) => row.nomenclature?.brand,
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      classes: () => "bold",
      editable: false,
      footer: () => null,
    },
    {
      dataField: "name",
      text: "Наименование",
      formatter: (cell, row) => (
        <Cut>{row.nomenclature?.name}</Cut>
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "inStock",
      text: "Ост.",
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
      formatter: (cell, row) => (
        <div className="warehouse-container">
          {(cell || row.inStockAll) && (
            <SkladPopup row={row}>
              <BarChart
                color={cell > 0 ? "green" : "red"}
                count={getExistRender(row)}
              />
            </SkladPopup>
          )}
        </div>
      ),
      headerClasses: () => "right",
      classes: (cell) => "right",
      footer: () => null,
      editable: false,
    },
    {
      dataField: "count",
      text: "Возвр",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      headerClasses: () => "right",
      footerClasses: () => "right",
      classes: () => "right",
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
      validator: (newValue, row) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Должны быть только цифры",
          };
        }
        if (newValue > row.countOld) {
          return {
            valid: false,
            message: "Не должно быть больше чем кол-во",
          };
        }
        if (newValue < 0) {
          return {
            valid: false,
            message: "Кол-во не может быть меньше 0",
          };
        }
        return true;
      },
    },
    {
      dataField: "price",
      text: "Цена",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      headerClasses: () => "right",
      footerClasses: () => "right",
      classes: () => "right",
      footer: (columnData) => null,
      editable: false,
    },
    {
      dataField: "summ",
      text: "Сумма",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      headerClasses: () => "right",
      footerClasses: () => "right",
      classes: () => "right",
      footer: (columnData) =>
        priceFormat(columnData.reduce((acc, item) => acc + item, 0)),
      editable: false,
    },
    {
      dataField: "warehouse",
      text: "Склад",
      headerStyle: (colum, colIndex) => {
        return { width: "25%" };
      },
      formatter: (cell, row) => (
        <ChangeWarehouse
          row={row}
          warehouses={warehouses}
          loading={loading}
          setLoading={setLoading}
          setProducts={setProducts}
        />
      ),
      footer: () => null,
      editable: false,
    },
    {
      dataField: "",
      text: "",
      formatter: (cell, row) => (
        <>
          {row.reserveProduct > 0 && (
            <TableButton
              Icon={Rewind}
              onClick={() => removeHandler(row.id)}
            />
          )}
        </>
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "4%" };
      },
      footer: () => null,
      editable: false,
    },
    {
      dataField: "",
      text: "",
      headerStyle: (colum, colIndex) => {
        return { width: "25%" };
      },
      footer: () => null,
      editable: false,
    },
  ];
  return (
    <>
      <BootstrapTable
        wrapperClasses="return-to-provider-product"
        keyField="id"
        data={products}
        columns={tableProductsColumns}
        bootstrap4
        bordered={false}
        cellEdit={cellEditFactory({
          mode: "click",
          blurToSave: true,
          afterSaveCell: (oldData, newData, row) =>
            productHandler(oldData, newData, row),
        })}
      />
    </>
  );
};
