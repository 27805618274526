import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { CloseButton } from "components/CloseButton";
import { updateOrder } from "services/api";
import { toast } from "react-toastify";
import { MySpinner } from "components/MySpinner";

export const LabelModal = ({
  id,
  openModal,
  setOpenModal,
  getAndSetDeliveryList,
  setQuantityLabels,
  quantityLabels,
  setQuantityPallets,
  quantityPallets,
  setShowPrintInvoice,
  setShowPrintLabels,
  loading
}) => {

  const printHandler = (complect = false) => {
    if (getAndSetDeliveryList) {
      getAndSetDeliveryList();
    }

    setOpenModal(false);

    setTimeout(() => {
      if (complect) {
        setShowPrintInvoice(true);
      } else {
        setShowPrintInvoice(false);
        setShowPrintLabels(true);
      }
    }, 500);

    updateOrder({
      id,
      quantityLabels,
      quantityPallets
    })
      .then(() => {
        getAndSetDeliveryList();
        toast.success("Кол-во мест успешно сохранено");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      });
  };

  const onClose = () => {
    setOpenModal(false);
  }

  useEffect(() => {
    if (!quantityPallets || !quantityLabels) return;
    if (quantityPallets > quantityLabels) {
      toast.error("Кол-во паллет не может быть больше кол-ва мест");
      setQuantityPallets(quantityLabels);
    }
  }, [quantityLabels, quantityPallets]);

  return (
    <Modal isOpen={openModal} toggle={onClose}>
      <ModalHeader>
        <div className="modal-title-text">Печать этикеток</div>
        <CloseButton onClick={onClose} />
      </ModalHeader>
      <ModalBody className="m-3">
        <Form>
          {loading && <MySpinner overlay />}
          <Input type="hidden" name="order" value={id} />

          <FormGroup>
            <Row>
              <Col md={3}>
                <Label>Общее кол-во мест</Label>
                <Input
                  type="number"
                  name="quantityLabels"
                  value={quantityLabels}
                  onChange={(e) => setQuantityLabels(() => {
                    return e.target.value
                      ? +e.target.value < 0 ? 0 : +e.target.value
                      : "";
                  })}
                />
              </Col>
              <Col md={3}>
                <Label>Кол-во паллет</Label>
                <Input
                  type="number"
                  name="quantityPallets"
                  value={quantityPallets}
                  onChange={(e) => setQuantityPallets(() => {
                    return e.target.value
                      ? +e.target.value < 0 ? 0 : +e.target.value
                      : "";
                  })}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Button color="primary" size="lg" onClick={() => printHandler(true)} className="mr-2">
              Печать комплекта документов
            </Button>
            <Button color="primary" size="lg" onClick={() => printHandler()}>
              Печать этикеток
            </Button>
          </FormGroup>
        </Form>

        <div className="d-flex justify-content-end">
          <Button type="button" onClick={onClose} color="secondary">
            Закрыть
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};
